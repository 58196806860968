import React from 'react'
import { graphql } from 'gatsby'
import { rhythm } from '../utils/typography'
import { Top } from '../components/top'
import { Head } from '../components/head'
import { Footer } from '../components/footer'
import * as Lang from '../constants'
import me from '../../content/assets/sarwech.jpg'

export default ({ data, title, location }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const resumes = data.allMarkdownRemark.edges

  const resume = resumes
    .filter(({ node }) => node.frontmatter.lang === Lang.ENGLISH)
    .map(({ node }) => node)[0]

  return (
    <div>
      <Top title={title} location={location} rootPath={rootPath} />
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(32),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <h1>Sarwech Shar Portfolio</h1>
        <div className="profile-container">
          <img src={me} className="profile-image" width={150} />
          <div className="profile-intro" style={{ fontSize: 18 }}>
            <p>
              👋 I’m Sarwech, a web developer and startup founder. I've helped
              early stage startups build MVPs (in React/JS), get their products
              in front of users and find traction.
            </p>
            <p>
              If you'd like to work together or discuss a project,{' '}
              <a href="mailto:sarwech.shar@gmail.com" target="_blank">
                let's chat
              </a>
              .
            </p>
          </div>
        </div>

        <div dangerouslySetInnerHTML={{ __html: resume.html }} />
        <Footer />
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { category: { eq: null } } }) {
      edges {
        node {
          id
          excerpt(pruneLength: 160)
          html
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            lang
          }
        }
      }
    }
  }
`
